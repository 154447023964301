import React from 'react'
import renderBlok from '@renderBlok'
import SbEditable from 'storyblok-react'
import { Container, Grid, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { H4 } from '@system'
import PageContainer from '@system/page-container'
import ContentContainer from '@system/content-container'

const useStyles = makeStyles((theme) => ({
  headline: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
    padding: '0px 24px 55px',
    fontWeight: theme.typography.fontWeightRegular,
    [theme.breakpoints.up('sm')]: {
      padding: '0px 90px 55px',
    },
    display: 'block',
  },
  cards: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    padding: '0px 8px',
    [theme.breakpoints.up('sm')]: {
      padding: '0px 48px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0px 24px',
    },
  },
}))

const HeadlineWith4ColModule = (props) => {
  const numberOfColumns =
    !!props.blok.columns &&
    !!props.blok.columns.length &&
    12 / props.blok.columns.length
  const classes = useStyles()
  return (
    <SbEditable content={props.blok}>
      <PageContainer>
        <ContentContainer>
          <Box py={5}>
            <H4 className={classes.headline}>{props.blok.headline}</H4>
            <Grid container>
              {props.blok.columns.map((blok) => (
                <Grid
                  item
                  key={blok._uid}
                  xs={numberOfColumns % 2 ? 6 : 12}
                  md={numberOfColumns}
                  className={classes.cards}
                >
                  {renderBlok(blok)}
                </Grid>
              ))}
            </Grid>
          </Box>
        </ContentContainer>
      </PageContainer>
    </SbEditable>
  )
}

export default HeadlineWith4ColModule
